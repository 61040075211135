export default `
mutation UpdateLastSignIn($userId: String!){
  UpdateLastSignIn(userId: $userId){
    id,
    identityId,
    displayName,
    email,
    lastSignInTimestamp
  }
}
`;
