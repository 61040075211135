import * as actionsTypes from "./actions-types";
import * as mutationTypes from "./mutations-types";
import {
  signIn,
  signOff,
  resetPassword,
  createNewPassword,
} from "src/services/login";
import {
  getUserInfo,
  getClientsByUser,
  createNewClient,
  createNewUser,
  createNewProject,
  getProfilesByClientId,
  updateLastSignIn,
  updateUser,
  deleteUser,
} from "src/services/cv-api/module/user/user.js";
import { firestore } from "src/boot/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import {
  updateAgentAndEventTaken,
  checkURLExist,
  serPropertiesUserAnalytics,
} from "src/services/cv-api/firebase";
import { sortItems, dateUTCToLocal } from "src/utils/functions";
import posthog from "posthog-js";

let unsubscribe = null;

const SIGN_IN = async ({ commit }, { email, password }) => {
  try {
    const user = await signIn(email, password);

    const userInfo = await getUserInfo(user.uid);
    const lastSignInUser = await updateLastSignIn(userInfo.GetUserInfo.id);

    const fullName = userInfo.GetUserInfo.displayName.split(" ");
    const initials =
      userInfo.GetUserInfo.firstName && userInfo.GetUserInfo.lastName
        ? userInfo.GetUserInfo.firstName.charAt(0) +
          userInfo.GetUserInfo.lastName.charAt(0)
        : fullName.shift().charAt(0) + fullName.pop().charAt(0);

    sortItems(userInfo.GetUserInfo.clients, "displayName");
    userInfo.GetUserInfo.clients.map((client) => {
      sortItems(client.projects, "displayName");
      sortItems(client.settings.eventStatuses, "displayName");
      sortItems(client.settings.eventTypes, "displayName");
      sortItems(client.settings.eventTagCommentsList, "displayName");
      sortItems(client.settings.eventTypesPriorities, "id");
    });
    const userToCommit = {
      identityId: user.uid,
      displayName: userInfo.GetUserInfo.displayName,
      email: userInfo.GetUserInfo.email,
      id: userInfo.GetUserInfo.id,
      identityProvider: userInfo.GetUserInfo.identityProvider,
      profileId: userInfo.GetUserInfo.profileId,
      root: userInfo.GetUserInfo.root,
      initialName: initials,
      profile: userInfo.GetUserInfo.profile,
      clients: userInfo.GetUserInfo.clients,
      lastSignIn: dateUTCToLocal(
        lastSignInUser.UpdateLastSignIn.lastSignInTimestamp
      ),
    };
    await serPropertiesUserAnalytics(userInfo.GetUserInfo);

    commit(mutationTypes.SET_USER, userToCommit);
    if (!userToCommit.root) {
      const projectToCommit = {
        displayName: userToCommit.clients[0].projects[0].displayName,
        id: userToCommit.clients[0].projects[0].id,
        clientId: userToCommit.clients[0].id,
        clientName: userToCommit.clients[0].displayName,
        dashboards: userToCommit.clients[0].projects[0].dashboards,
        settings: userToCommit.clients[0].settings,
        geo: userToCommit.clients[0].projects[0].geo
      };

      commit(mutationTypes.SET_PROJECT_SELECTED, projectToCommit);
    }
  } catch (error) {
    console.log("ERROR SIGN IN");
    commit(mutationTypes.SET_USER_LOADING, false);
  } finally {
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

const FETCH_CLIENTS_BY_USER = async (
  { commit, state },
  { userId, clientId }
) => {
  try {
    commit(mutationTypes.SET_USER_LOADING, true);
    const clients = await getClientsByUser(userId, clientId);
    let dataToCommit = clients.GetClients;
    sortItems(dataToCommit, "id");

    commit(mutationTypes.SET_CLIENTS_BY_USER, dataToCommit);
    commit(mutationTypes.SET_USER_LOADING, false);
  } catch (error) {
    console.log("ERROR CLIENTS USER");
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

const SIGN_OUT = async ({ commit, state, rootState }) => {
  const eventId = rootState.events.eventIdSelected;
  try {
    const response = await signOff();
    if (eventId) {
      updateAgentAndEventTaken(state.projectSelected.clientId, eventId);
    }
    return response;
  } catch (error) {
    console.log("ERROR LOG OUT");
  } finally {
    commit(mutationTypes.SET_USER_LOADING, false);
    commit(mutationTypes.SET_PROJECT_SELECTED, {});
    commit(mutationTypes.SET_CLIENT_SELECTED, {});
    commit(mutationTypes.SET_ACTIVE_ITEM_MENU, "");
    commit(mutationTypes.SET_USER, {});
    posthog.reset()
  }
};

const ADD_NEW_CLIENT = async ({ commit, state }) => {
  try {
    const newClient = {
      displayName: state.newClient.clientName,
      rut: state.newClient.clientRut,
    };
    const newClientAdded = await createNewClient(newClient);
    commit(
      mutationTypes.SET_UPDATE_USER_BY_NEW_CLIENT,
      newClientAdded.CreateClient
    );
  } catch (error) {
    console.log("ERROR NEW CLIENT");
  }
};

const ADD_NEW_USER = async ({ commit, state }, { clientId, projectId }) => {
  try {
    commit(mutationTypes.SET_USER_LOADING, true);

    const newUser = {
      profileId: state.newUser.profileId,
      displayName: state.newUser.displayName,
      firstName: state.newUser.firstName,
      lastName: state.newUser.lastName,
      email: state.newUser.email,
      root: state.newUser.root,
      identityProvider: "FIREBASE",
      identityId: null,
    };

    const userCreated = await createNewUser(clientId, projectId, newUser);

    commit(
      mutationTypes.SET_PASSWORD_GENERATED,
      userCreated.CreateUser.password
    );
    commit(mutationTypes.SET_USER_LOADING, false);
  } catch (error) {
    console.log("ERROR NEW USER");
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

const ADD_NEW_PROJECT = async (
  { commit, state },
  { clientId, projectName }
) => {
  try {
    const project = {
      displayName: projectName,
    };
    const newProject = await createNewProject(clientId, project);
    commit(mutationTypes.SET_UPDATE_USER_BY_NEW_PROJECT, {
      project: newProject.CreateProject,
      clientId,
    });
  } catch (error) {
    console.log("ERROR NEW PROJECT");
  }
};

const RESTORE_PASSWORD = async ({ commit }, { email }) => {
  commit(mutationTypes.SET_USER_LOADING, true);
  commit(mutationTypes.SET_ERROR, false);
  try {
    const result = await resetPassword(email);
    if (result) {
      commit(mutationTypes.SET_ERROR, true);
    }
  } catch (error) {
    console.log("ERROR RESET PASSWORD");
    commit(mutationTypes.SET_USER_LOADING, false);
  } finally {
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

const CREATE_NEW_PASSWORD = async (
  { commit },
  { mode, actionCode, email, newPassword }
) => {
  commit(mutationTypes.SET_USER_LOADING, true);
  commit(mutationTypes.SET_ERROR, false);

  try {
    const result = await createNewPassword(
      mode,
      actionCode,
      email,
      newPassword
    );
    if (result) {
      if (result.code === "auth/invalid-action-code") {
        commit(mutationTypes.SET_ERROR, true);
      }
    }
    return result;
  } catch (error) {
    console.log("ERROR CREATE PASSWORD");
    commit(mutationTypes.SET_USER_LOADING, false);
  } finally {
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

const CHECK_SESSION = async ({ commit, dispatch }, { uid, localSession }) => {
  try {
    // unsubscribe = firestore

  const q = query(collection(firestore, "activity-on-users"), where("__name__", "==", `${uid}`));
  unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "modified") {
        // console.log("Modified city: ", change.doc.data());
        if (change.doc.data().lastActive !== localSession) {
          dispatch("SIGN_OUT");
          window.location.reload();
        }
      }
    });
  });

  }
  catch(error) {
    unsubscribe()
    console.log("ERROR CHECKING SESSION");
  }
};

const UNLISTEN_SESSION = async ({ commit, state }) => {
  if (unsubscribe) {
    unsubscribe();
  }
};

const GET_LOGO_CLIENT = async ({ commit, state }) => {
  let urlLogo = "";
  try {
    const logoFound = await checkURLExist(state.projectSelected.clientId);
    if (logoFound.fullPath) {
      urlLogo = `${process.env.API_STORAGE}/${logoFound.bucket}/${logoFound.fullPath}`;
    } else urlLogo = undefined;
  } catch (error) {
    urlLogo = undefined;
  } finally {
    commit(mutationTypes.SET_URL_LOGO, urlLogo);
  }
};

const FETCH_PROFILES_BY_CLIENT_ID = async ({ commit, state }, { clientId }) => {
  const dataToCommit = [];
  try {
    const profiles = await getProfilesByClientId(clientId);
    profiles.GetProfilesByClientId.map((profile) => {
      dataToCommit.push({
        label: profile.displayName,
        value: profile.id,
      });
    });
    sortItems(dataToCommit, "label");

    commit(mutationTypes.SET_PROFILES_BY_CLIENT_ID, dataToCommit);
  } catch (error) {
    console.log("ERROR PROFILES BY CLIENT");
  }
};

const UPDATE_USER = async ({ commit, state }, { userData }) => {
  try {
    commit(mutationTypes.SET_USER_LOADING, true);

    const userUpdated = await updateUser(userData);

    commit(mutationTypes.SET_USER_UPDATED, userUpdated.UpdateUser.id);
    commit(mutationTypes.SET_USER_LOADING, false);
  } catch (error) {
    console.log("ERROR UPDATE USER");
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

const LAST_INTERACTION_USER = async ({ commit, state }, { userId }) => {
  try {
    await updateLastSignIn(userId);
  } catch (error) {
    console.log("ERROR LAST INTERACTION");
  }
};

const DELETE_USER = async ({ commit, state }, { userId }) => {
  try {
    commit(mutationTypes.SET_USER_LOADING, true);

    const userDeleted = await deleteUser(userId);

    commit(mutationTypes.SET_USER_DELETED, userDeleted.DeleteUser);
    commit(mutationTypes.SET_USER_LOADING, false);
  } catch (error) {
    console.log("ERROR DELETE USER");
    commit(mutationTypes.SET_USER_LOADING, false);
  }
};

export default {
  [actionsTypes.SIGN_IN]: SIGN_IN,
  [actionsTypes.SIGN_OUT]: SIGN_OUT,
  [actionsTypes.FETCH_CLIENTS_BY_USER]: FETCH_CLIENTS_BY_USER,
  [actionsTypes.ADD_NEW_CLIENT]: ADD_NEW_CLIENT,
  [actionsTypes.ADD_NEW_USER]: ADD_NEW_USER,
  [actionsTypes.ADD_NEW_PROJECT]: ADD_NEW_PROJECT,
  [actionsTypes.RESTORE_PASSWORD]: RESTORE_PASSWORD,
  [actionsTypes.CREATE_NEW_PASSWORD]: CREATE_NEW_PASSWORD,
  [actionsTypes.CHECK_SESSION]: CHECK_SESSION,
  [actionsTypes.UNLISTEN_SESSION]: UNLISTEN_SESSION,
  [actionsTypes.GET_LOGO_CLIENT]: GET_LOGO_CLIENT,
  [actionsTypes.FETCH_PROFILES_BY_CLIENT_ID]: FETCH_PROFILES_BY_CLIENT_ID,
  [actionsTypes.UPDATE_USER]: UPDATE_USER,
  [actionsTypes.LAST_INTERACTION_USER]: LAST_INTERACTION_USER,
  [actionsTypes.DELETE_USER]: DELETE_USER,
};
