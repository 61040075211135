import { auth} from "src/boot/firebase";
import {    
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged, 
  verifyPasswordResetCode, confirmPasswordReset ,
  sendPasswordResetEmail
} 
from "firebase/auth";
import { errorAlert } from "src/utils/notifies";
import { LocalStorage } from "quasar";
import { userLogged } from "src/services/cv-api/firebase"


const signIn = async (email, password) => {
  try {
    let user = await signInWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        return userCredential.user;
      }
    );
    user = user.toJSON();

    LocalStorage.clear();
    userLogged(user.uid)

    return user;

  } catch (error) {
    errorAlert("Credenciales incorrectas. Intentalo nuevamente");
    throw error;
  }
};

const signOff = async () => {
  try {
    signOut(auth)
    .then(() => {
      const user = auth.currentUser;
      return !user;
    })
    .catch((error) => {
      throw error;
    });
  } catch{
    console.log("error")
  }
};

const getCurrentUser = async () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve({
          email: user.email,
          uid: user.uid,
          token: user.accessToken,
        });
      } else {
        resolve(null);
      }
    });
  });
};

const resetPassword = async (email) => {
  try {
    sendPasswordResetEmail(auth, email).then(() => {
      // Password reset confirmation sent. Ask user to check their email.
    }).catch((error) => {
      // Error encountered while sending password reset code.
      errorAlert("Error en restaurar contraseña. Inténtalo nuevamente");
    });
  } catch (error) {
    errorAlert("Error en restaurar contraseña. Inténtalo nuevamente");    
  }
};

const createNewPassword = async (mode, actionCode, email, newPassword) => {
  let result;
  try{
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode).then((email) => {
      const accountEmail = email;
      const newPassword = newPassword
      // Save the new password.
      confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
        result = resp
      }).catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        result = error
      });
    }).catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      result = error
    });
  return result
  }catch(error){
    result = error
  }
};

export {
  signIn,
  signOff,
  getCurrentUser,
  resetPassword,
  createNewPassword,
};
